/*
 * @Date: 2023-03-22 10:20:51
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-01 10:21:09
 * @FilePath: \J1-M3-QRP-CODE\pages\_app.js
 */
import App from 'next/app';
import Head from 'next/head';

import React from 'react';

import { ConfigProvider } from 'antd';

import '$DATA/Less/merge.less';



export default class MyApp extends App {
	render(){
		const { Component, pageProps } = this.props;
		return (
			<>
				<Head>
					<link rel="icon" type="image/png" sizes="32x32" href="/static/images/favicon.ico"></link>
					<title>JBO Website chính thức | Nhà cái cá cược uy tín JBO Việt Nam</title>
				</Head>
				<ConfigProvider>
					<Component { ...pageProps } />
				</ConfigProvider>
			</>
		)
	}
}